import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { Helmet } from "react-helmet";
import {
  IoIosDownload,
  IoIosCall,
  IoIosMail,
  IoIosCheckmarkCircle,
  IoIosCopy,
  IoMdOpen,
} from "react-icons/io";

import { Button, Container, Wrapper, Row, Box } from "../../components/util";
import { Breadcrumb, Accordion } from "../../components/site";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Image from "../../components/image";

import FlexiTimeLogo from "../../images/logos/FlexiTime/FlexiTime_WFM_Full.svg";
import PayHeroLogo from "../../images/logos/PayHero/PayHero_Full.svg";
import DroppahLogo from "../../images/logos/Droppah/Droppah_Full_Black.svg";
import InvoxyLogo from "../../images/logos/Invoxy/Invoxy_Full.svg";

const PartnerResourceWrapper = styled.div`
  width: 100%;
  padding: 100px 1rem;
  background-color: rgba(255, 255, 255, 0.6);
`;

const CopiedMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  color: #5eb22e;
  font-size: 1.6rem;
  animation: reveal 0.25s;

  svg {
    position: relative;
    top: 2px;
    margin-right: 8px;
  }

  @keyframes reveal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const CopyButton = (props) => {
  const [copySuccess, setCopySuccess] = useState("");

  function copyToClip() {
    var blurb = document.getElementById("blurb_" + props.product).innerHTML;

    function listener(e) {
      e.clipboardData.setData("text/html", blurb);
      e.clipboardData.setData("text/plain", blurb);
      e.preventDefault();
    }
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);

    setCopySuccess(true);
    setTimeout(function () {
      setCopySuccess(false);
    }, 4000);
  }

  return (
    <Button
      className="grey -md -full"
      onClick={() => {
        copyToClip();
      }}
      css={{ marginTop: "50px" }}
      atag
    >
      <IoIosCopy css={{ top: "2px", marginRight: "8px" }} />{" "}
      {copySuccess ? "Copied" : "Copy Description"}
    </Button>
  );
};

const Marketing = (props) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      if (props.location.search.substr(1) === "reload") {
        window.history.pushState(
          {},
          null,
          props.location.origin + props.location.pathname
        );
        window.location.reload(true);
      }
    }
  }, []);

  return (
    <Layout>
      <Seo
        title="Marketing & Templates | Partner Resources | FlexiTime Partner"
        pathname={props.location.pathname}
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Breadcrumb
        parentPage="Partner Resources"
        parentPageLink="/partner-resources"
        currentPage="Marketing & Templates"
      />
      <Container>
        <StaticQuery
          query={graphql`
            query {
              desktop: file(
                relativePath: {
                  eq: "partner-toolkit/Partner Resource Pic4.png"
                }
              ) {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          `}
          render={(data) => {
            const imageData = data.desktop.childImageSharp.fluid;
            return (
              <BackgroundImage
                Tag="section"
                fluid={imageData}
                backgroundColor={`#fff`}
              >
                <PartnerResourceWrapper className="-textCenter">
                  <div>
                    <h1>Marketing & Templates</h1>
                    <h4>
                      Get marketing material like client flyers, logos & more
                    </h4>
                  </div>
                </PartnerResourceWrapper>
              </BackgroundImage>
            );
          }}
        />
      </Container>
      <Container>
        <Wrapper maxWidth={1000}>
          <Box stackGap={10} className="-textCenter">
            <h2>Grow your practice with FlexiTime</h2>
            <h4>
              The FlexiTime Partner Program rewards you with a range of benefits
              to help your practice and your clients succeed. We want to make it
              easy for you to promote our products to your clients and potential
              clients so we can grow together.
            </h4>
          </Box>
          <Box
            // css={{
            //   padding: "50px !important",
            //   borderRadius: "5px",
            //   backgroundColor: "#f9f9f9",
            // }}
            stackGap={60}
          >
            <Box stackGap={30}>
              <h3 className="-fontDark">Co-marketing funding</h3>
              <h4>
                Gold and Silver partners have access to our co-marketing fund.
                Please check the eligibility criteria and apply below.
              </h4>
              <h4>
                <a
                  href="/media/partners/FlexiTime_PartnerComarketingFund_Guide.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="link-floating"
                >
                  FlexiTime Co-Marketing Fund Guide
                  <IoMdOpen css={{ top: "3px", marginLeft: "7px" }} />
                </a>
              </h4>
              <h4>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeUKqS_yXcBWR9qUP3LpL4lJTl8x7Q8khXrfFlGKsogG3WevA/viewform?usp=sf_link"
                  target="_blank"
                  rel="noreferrer"
                  className="link-floating"
                >
                  FlexiTime Marketing Fund Application Form
                  <IoMdOpen css={{ top: "3px", marginLeft: "7px" }} />
                </a>
              </h4>
            </Box>
            <hr />
            <Box stackGap={30}>
              <div>
                <h3 className="-fontDark">Marketing resources</h3>
              </div>
              <h4>
                <a
                  href="/media/FlexiTime_Partner_Logos.zip"
                  className="link-floating"
                  download
                >
                  FlexiTime Partner Logos{" "}
                  <IoIosDownload css={{ top: "1px", marginLeft: "5px" }} />
                </a>
              </h4>
              <h4>
                <a
                  href="/media/Product_Logos.zip"
                  className="link-floating"
                  download
                >
                  Product Logos{" "}
                  <IoIosDownload css={{ top: "1px", marginLeft: "5px" }} />
                </a>
              </h4>
              <h4>
                <a
                  href="/media/FlexiTime_Product_Screenshots.zip"
                  className="link-floating"
                  download
                >
                  Product Screenshots{" "}
                  <IoIosDownload css={{ top: "1px", marginLeft: "5px" }} />
                </a>
              </h4>
              <h4>
                <a
                  href="/media/partners/FlexiTime_Brand_Guidelines.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="link-floating"
                >
                  Brand Guidelines{" "}
                  <IoMdOpen css={{ top: "3px", marginLeft: "7px" }} />
                </a>
              </h4>
              {/* <h4>
                <a
                  href="/media/PayHero_PartnerClientFlyer.pdf"
                  className="link-floating"
                  download
                >
                  PayHero Client Flyer{" "}
                  <span className="-fontNormal">(268KB)</span>{" "}
                  <IoIosDownload css={{ top: "1px", marginLeft: "8px" }} />
                </a>
              </h4>
              <h4>
                <a
                  href="/media/PayHero Partner - Promoting To Your Clients.pdf"
                  className="link-floating"
                  download
                >
                  Promoting to your clients{" "}
                  <span className="-fontNormal">(1.3MB)</span>{" "}
                  <IoIosDownload css={{ top: "1px", marginLeft: "8px" }} />
                </a>
              </h4> */}
            </Box>
            <hr />
            <Box stackGap={30}>
              <Box>
                <h3 className="-fontDark">FlexiTime On Your Website</h3>
                <p>
                  When promoting FlexiTime or our products on your website,
                  please use the following descriptions accompanied by the
                  correct logo (as shown below). You can download our logos{" "}
                  <a href="/media/Product_Logos.zip" download>
                    here
                  </a>
                  .
                </p>
              </Box>
              <Accordion title="FlexiTime">
                <Row stackGap={20} alignCenter>
                  <Box size={50}>
                    <img
                      className="-center"
                      src={FlexiTimeLogo}
                      alt="FlexiTime"
                      height="80"
                    />
                  </Box>
                  <Box size={50} id="blurb_FlexiTime">
                    <h2>About FlexiTime</h2>
                    <p>
                      FlexiTime provides a suite of online{" "}
                      <a href="https://www.flexitime.works" target="_blank">
                        workforce management software
                      </a>{" "}
                      solutions for planning, rostering, tracking and paying
                      employees. They help thousands of companies streamline and
                      automate their people and payroll processes.
                    </p>
                    <p>FlexiTime’s products include:</p>
                    <ul>
                      <li>
                        <b>PayHero</b> - Pay employees right with smart, modern
                        & seriously compliant{" "}
                        <a href="https://www.payhero.co.nz" target="_blank">
                          payroll software
                        </a>
                      </li>
                      <li>
                        <b>Droppah</b> - Control costs and optimise coverage
                        with{" "}
                        <a href="https://www.droppah.com" target="_blank">
                          rostering and time clock software
                        </a>{" "}
                        for managing shift workers
                      </li>
                      <li>
                        <b>Karmly</b> - Increase billing accuracy and get paid
                        faster with contractor management and{" "}
                        <a href="https://www.karmly.com/recruitment" target="_blank">
                          job management software
                        </a>
                      </li>
                    </ul>
                  </Box>
                </Row>
                <CopyButton product="FlexiTime" />
              </Accordion>
              <Accordion title="PayHero">
                <Row stackGap={20} alignCenter>
                  <Box size={50}>
                    <img
                      className="-center"
                      src={PayHeroLogo}
                      alt="PayHero"
                      height="60"
                    />
                  </Box>
                  <Box size={50} id="blurb_PayHero">
                    <h2>About PayHero</h2>
                    <p>
                      PayHero is{" "}
                      <a href="https://www.payhero.co.nz" target="_blank">
                        payroll software
                      </a>{" "}
                      that makes payday super easy, especially for companies
                      with part time, casual, contract and variable hour staff.
                    </p>
                    <ul>
                      <li>
                        Easily track hourly work with options for mobile
                        timesheets, GPS or photo time clock.
                      </li>
                      <li>
                        Allow employees to request leave through the app that
                        managers can approve from an email.
                      </li>
                      <li>
                        Solve your public holiday nightmare by automatically
                        determining otherwise working days and entitlements.
                      </li>
                      <li>
                        Follow the latest Holidays Act guidance from MBIE and
                        ensure compliant leave calculations, even when work
                        patterns change. PayHero stores leave balances in Weeks
                        and reviews employees’ recent work patterns when
                        evaluating leave.
                      </li>
                      <li>
                        Assign payroll costs to the general ledger and tracking
                        categories with deep Xero integration.
                      </li>
                      <li>
                        Automate your payday filing obligations with direct
                        integration to Inland Revenue.
                      </li>
                    </ul>
                    <p>
                      Find out more or try{" "}
                      <a href="https://www.payhero.co.nz" target="_blank">
                        PayHero Payroll Software
                      </a>{" "}
                      for free.
                    </p>
                  </Box>
                </Row>
                <CopyButton product="PayHero" />
              </Accordion>
              <Accordion title="Droppah">
                <Row stackGap={20} alignCenter>
                  <Box size={50}>
                    <img
                      className="-center"
                      src={DroppahLogo}
                      alt="Droppah"
                      height="60"
                    />
                  </Box>
                  <Box size={50} id="blurb_Droppah">
                    <h2>About Droppah</h2>
                    <p>
                      Droppah is{" "}
                      <a href="https://www.droppah.com" target="_blank">
                        rostering software
                      </a>{" "}
                      with inbuilt AI to make rostering easy.
                    </p>
                    <ul>
                      <li>
                        Auto-rostering uses skills, availability, shift
                        preferences, wage costs, and other requirements to
                        automatically build highly optimised rosters.
                      </li>
                      <li>
                        Accurately cost staff against sales to ensure you’ve got
                        the right coverage at the right times. Set strict roster
                        start times to eliminate unplanned overtime.
                      </li>
                      <li>
                        Increase your payroll accuracy with an in-built photo
                        time clock that tracks to-the-minute timesheets and
                        breaks.
                      </li>
                      <li>
                        Monitor the day’s attendance & breaks in real time with
                        a live view of people’s work status.
                      </li>
                      <li>
                        Send manager-approved timesheets directly to payroll. No
                        more paper timesheets, manual calculations or data
                        entry.
                      </li>
                    </ul>
                    <p>
                      Find out more or try{" "}
                      <a href="https://www.droppah.com" target="_blank">
                        Droppah Rostering Software
                      </a>{" "}
                      for free.
                    </p>
                  </Box>
                </Row>
                <CopyButton product="Droppah" />
              </Accordion>
              <Accordion title="Karmly">
                <Row stackGap={20} alignCenter>
                  <Box size={50}>
                    <img
                      className="-center"
                      src={InvoxyLogo}
                      alt="Karmly"
                      height="60"
                    />
                  </Box>
                  <Box size={50} id="blurb_Invoxy">
                    <h2>About karmly</h2>
                    <p>
                      Karmly is{" "}
                      <a href="https://www.karmly.com/recruitment" target="_blank">
                        contractor management and job management software
                      </a>{" "}
                      that increases your billing accuracy and helps you get
                      paid faster.
                    </p>
                    <ul>
                      <li>
                        Get your employees & contractors to track time against
                        specific clients, projects or jobs with mobile
                        timesheets.
                      </li>
                      <li>
                        Make things simple for your clients by letting them
                        review and approve timesheets in a single click from an
                        email.
                      </li>
                      <li>
                        Quickly create and send large batches of invoices to
                        multiple clients, all tailored to their exact
                        requirements. Use the e-Invoicing option to get paid
                        faster than ever by government and large business
                        clients.
                      </li>
                      <li>
                        Automate compliance and induction tasks with
                        customisable employee and contractor onboarding.
                      </li>
                      <li>
                        Track staff productivity, revenue and costs in real time
                        as timesheets are submitted. See forecast revenue and
                        build custom reports to keep your finger on the pulse of
                        your business.
                      </li>
                    </ul>
                    <p>
                      Find out more or try{" "}
                      <a href="https://www.karmly.com/recruitment" target="_blank">
                        Karmly Contractor Management Software
                      </a>{" "}
                      for free.
                    </p>
                  </Box>
                </Row>
                <CopyButton product="Invoxy" />
              </Accordion>
            </Box>
            <hr />
            <Box stackGap={30}>
              <Box>
                <h3 className="-fontDark">FlexiTime Partner Directory</h3>
                <p>
                  Gold, Silver and Bronze partners are eligible to be listed on
                  the FlexiTime partner directory. Please fill in the form to
                  create or update your directory listing.
                </p>
              </Box>
              <h4>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdISbtgLvY8tut7zUTs0qJlqawqkTaCpTE-D_nYr9jGvaaGIw/viewform?usp=sf_link"
                  target="_blank"
                  rel="noreferrer"
                  className="link-floating"
                >
                  FlexiTime Partner Directory Form
                  <IoMdOpen css={{ top: "3px", marginLeft: "7px" }} />
                </a>
              </h4>
            </Box>
          </Box>
        </Wrapper>
      </Container>
      <Container className="-textCenter secondary">
        <Wrapper stackGap={80}>
          <h2>Need help? Get in touch</h2>
          <Row css={{ maxWidth: "900px" }} className="-center">
            <Box className="-center" size={50} stackGap={30}>
              <Image
                filename="PeteAshby_Circle.png"
                alt="Pete Ashby | FlexiTime Partner Contact"
                width={80}
                centerImage
              />
              <h4>
                <span className="-fontBold">Pete Ashby</span>
                <br />
                Head of Discovery
              </h4>
              <h4 className="-fontBold">
                <a
                  href=" https://calendly.com/flexitime-partners/partner-manager"
                  target="_blank"
                  rel="noreferrer"
                  className="link-floating -center"
                >
                  <IoIosCall css={{ position: "relative", top: "3px" }} /> Book
                  a Call
                </a>
              </h4>
              <h4 className="-fontBold">
                <a
                  className="link-floating -center"
                  href="mailto:pete@flexitime.works"
                >
                  <IoIosMail
                    css={{
                      position: "relative",
                      top: "3px",
                      marginRight: "5px",
                    }}
                  />{" "}
                  pete@flexitime.works
                </a>
              </h4>
            </Box>
          </Row>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Marketing;
